(function ($) { $(document).ready(init);
	var bp = 0;
	var cp = 0;
	var cY = 0;
	var height = 0;
	var mY = {};
	function init(){

        if (Drupal.settings.timer) {
            var banner_popup = $.cookie('banner_popup');

            if (banner_popup == null) {
                var timer = parseInt(Drupal.settings.timer) * 1000;

                setTimeout(function() {
                    $.cookie('banner_popup', 1);
                    $('#block-myblocks-popup-banner').show();
                    $('body').addClass('popup-active');
                }, timer);
            }
        }

				if (Drupal.settings.timer_bottom) {
            var banner_popup_bottom = $.cookie('banner_popup_bottom');

            if (banner_popup_bottom == null) {
                var timer = parseInt(Drupal.settings.timer_bottom) * 1000;

                setTimeout(function() {
                    $.cookie('banner_popup_bottom', 1);
                    $('#block-myblocks-popup-banner-bottom').show();
                }, timer);
            }
        }

        $('.popup-close-btn').click(function(){
            $('#block-myblocks-popup-banner').hide();
            $('#block-myblocks-popup-banner-bottom').hide();
            $('body').removeClass('popup-active');
        });

		$('#subscription-close-btn').bind('click', function(){
			//$('#block-myblocks-subscription').hide(500);
		});
		//if(!getCookie('bannersub').length){
			//setCookie('bannersub', 1);
			//$('#block-myblocks-subscription').show(500);
		//}

		/* $('#subs-banner-close-btn').bind('click', function(){
			$('#block-myblocks-subscription-banner').hide(500);
		});
		if(!getCookie('bannersub-center').length){
			setCookie('bannersub-center', 1);
			$('#block-myblocks-subscription-banner').show(500);
		} */


		if ($('#block-webform-client-block-28088').length) {
			if (!getCookie('subscribe').length) {
				setCookie('subscribe', 1);
				$('.popup-bg').fadeIn('slow');
				$('#block-webform-client-block-28088').show();
			}

			$('.popup-bg').click(function(){
				$(this).fadeOut('slow');
				$('#block-webform-client-block-28088').hide();
			});

		}
		$('.form-radio').each(function(){
			if($(this).hasClass('error')){
				$(this).parent().parent().parent().addClass('radio-error');
			};
		});
		$('.faq-item-link-register').click(function(){
			var scope = $(this);
			if($('.faq-item-link-error:visible').length){
				$('.faq-item-link-error:visible').slideUp('fast', function(){
						if(scope.attr('id') != $(this).attr('id')) $('#reg' + scope.attr('id')).slideDown('fast');
					}
				);
			} else {
				$('#reg' + $(this).attr('id')).slideDown('fast');
			}
		});

		$('#edit-submitted-3-na-pidpriiemstvi-bulo-provedeno-konkurs-na-zamishchennya .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-3-na-pidpriiemstvi-bulo-provedeno-konkurs-na-zamishchennya-vakantnoyi-posadi-nachalnika-viddilu').addClass('radio-error');
			};
		});
		$('#edit-submitted-a4 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-4-mizh-pidpriiemstvom-ta-universitetom-ukladeno-dogovir').addClass('radio-error');
			};
		});
		$('#edit-submitted-a5 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-5-pracivnik-viddilu-kadriv-oznayomiv-novopriynyatogo-pracivnika-z-nakazom-pro-priynyattya-na-robotu').addClass('radio-error');
			};
		});

		$('#edit-submitted-q1 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-1').addClass('radio-error');
			};
		});
		$('#edit-submitted-q2 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-2').addClass('radio-error');
			};
		});
		$('#edit-submitted-q3 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-3').addClass('radio-error');
			};
		});
		$('#edit-submitted-q4 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-4').addClass('radio-error');
			};
		});
		$('#edit-submitted-q5 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-5').addClass('radio-error');
			};
		});
		$('#edit-submitted-q11 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-11').addClass('radio-error');
			};
		});
		$('#edit-submitted-q12 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-12').addClass('radio-error');
			};
		});
		$('#edit-submitted-q13 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-13').addClass('radio-error');
			};
		});
		$('#edit-submitted-q14 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-14').addClass('radio-error');
			};
		});
		$('#edit-submitted-q15 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-15').addClass('radio-error');
			};
		});
		$('#edit-submitted-q21 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-21').addClass('radio-error');
			};
		});
		$('#edit-submitted-q21 .form-checkbox').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-21').addClass('radio-error');
			};
		});
		$('#edit-submitted-q22 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-22').addClass('radio-error');
			};
		});
		$('#edit-submitted-q22 .form-checkbox').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-22').addClass('radio-error');
			};
		});
		$('#edit-submitted-q23 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-23').addClass('radio-error');
			};
		});
		$('#edit-submitted-q24 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-24').addClass('radio-error');
			};
		});
		$('#edit-submitted-q24 .form-checkbox').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-24').addClass('radio-error');
			};
		});
		$('#edit-submitted-q25 .form-radio').each(function(){
			if($(this).hasClass('error')){
				$('#webform-component-25').addClass('radio-error');
			};
		});

		$('.form-checkbox').each(function(){
			if($(this).hasClass('error')){
				$('label', $(this).parent()).addClass('checkbox-error');
			};
		});
		$('.konkurs-error').click(function(){
			if($('.konkurs-error-text').hasClass('opened')){
				$('.konkurs-error-text').slideUp(300, function(){
					$('.konkurs-error-text').slideDown(300, function(){
						$('.konkurs-error-text').addClass('opened');
					});
				});
			} else {
				$('.konkurs-error-text').addClass('opened');
				$('.konkurs-error-text').slideDown();
			}
		});




		$('.slide-menu-title').click(function(){
			var menu = $(this).parent();
			var scope = $(this);
			if($('.slide-menu-content:visible', menu).length > 0){
				$(scope).removeClass('opened');
			} else {
				$(scope).addClass('opened');
			}
			$('.slide-menu-content:visible', menu).slideUp();
			$('.slide-menu-content:hidden', menu).slideDown();
		});
		$('.slide-item-title').click(function(){
			var menu = $(this).parent();
			var scope = $(this);
			if($('.slide-item-content:visible', menu).length > 0){
				$(scope).removeClass('opened');
			} else {
				$(scope).addClass('opened');
			}
			$('.slide-item-content:visible', menu).slideUp();
			$('.slide-item-content:hidden', menu).slideDown();
		});

		$(document).scroll(function(){
			var pos = $(document).scrollTop();
			if(pos > 116){
				$('.side-link').css('position', 'fixed');
				$('.side-link').css('top', $('body').css('padding-top'));
			} else {
				$('.side-link').css('position','absolute');
				$('.side-link').css('top', 0);
			}
		});

		$('.scroll-container').each(function(){
			height = $(this).height();
			if(height <= 215){
				$('.slider', $(this).parent()).addClass('disabled');
			};
		});
		$('.scroll-container').bind('mousewheel', function(e, delta){
			height = $(this).height();
			cY = parseInt($('.slider', $(this).parent()).css('margin-top'));
			var y = cY - delta * 5;
			return scrollBlock($(this), y);
		});

		$('.slider').bind('dragstart', function(e){
			bp = mY;
			cY = parseInt($(this).css('margin-top'));
			height = $('.scroll-container', $(this).parent().parent()).height();
		});

		$('.slider').bind('drag', function(e){
			var sc = $('.scroll-container', $(this).parent().parent());
			var y = mY - bp + cY;
			scrollBlock(sc, y);
		});

		$('body').mousemove(function(e){
			mY = e.pageY;
		});

		$('.faq-title').click(function(){
			if($('.faq-item-content:visible', $(this).parent()).length){
				$('.faq-item-content', $(this).parent()).slideUp();
			} else {
				$('.faq-item-content:visible').slideUp();
				$('.faq-item-content', $(this).parent()).slideDown();
			}
		});

		$('.timeMarafon').timeTo({
			//new Date('Fri Sep 22 2017 00:00:00 GMT+0300')
			timeTo: new Date('Sat Jul 28 2018 00:00:00 GMT+0300'),
			displayDays: 2,
			//theme: "black",
			displayCaptions: true,
			fontSize: 48,
			captionSize: 14,
			lang: 'ua'
		});


		var min_length = 30;
		var copy_el_id = 'copyinfo';
		var addText = '<br>Джерело: <a href="' + location.href + '">' + location.href + '</a><br>Будь-яке використання матеріалів можливе лише за наявності гіперпосилання.';
		$(document).bind({
			mousedown: function() {
				if ($('div').is($('#' + copy_el_id))) {
					$('#' + copy_el_id).remove();
				}
			},
			mouseup: function() {
				var copy_el;
				if (window.getSelection) {
					var sel = window.getSelection();
					var selText = sel.toString();
					if (!selText || selText.length < min_length) { return }

					if (!$('div').is($('#' + copy_el_id))) {
						copy_el = document.createElement('div');
						copy_el.id = copy_el_id;
						copy_el.innerHTML = addText;
					}

					var newSel = sel.getRangeAt(0);
					selText = newSel.cloneRange();
					selText.collapse(false);
					selText.insertNode(copy_el);
					newSel.setEndAfter(copy_el);
					sel.removeAllRanges();
					sel.addRange(newSel);
				} else if(document.selection) {
					sel = document.selection;
					newSel = sel.createRange();
					selText = newSel.text;
					if (!selText || selText.length < min_length) { return }

					copy_el = document.createElement('div');
					copy_el.id = copy_el_id;
					copy_el.innerHTML = addText;

					selText=newSel.duplicate();
					selText.collapse(false);
					selText.pasteHTML(copy_el.outerHTML);
					newSel.setEndPoint("EndToEnd",selText);
					newSel.select();
				}
			}
		});

    $('#btn-in').on('click', function(){
      $('#block-user-login').toggle();
    });

    $('#block-user-login h2').on('click', function(){
      $('#block-user-login').toggle();
    });


	}

	function scrollBlock(sc, y){
		if(sc.height() > 215){
			if(y < 0) y = 0;
			if(y > 185) y = 185;
			var y2 = (height - 215) / 185 * (-y);
			$('.slider', sc.parent()).css('margin-top', y);
			sc.css('margin-top', y2);
		}
		if(y == 185 || y == 0 || sc.height() <= 215) return true; else return false;
	}
}(jQuery))
